'use client';

import { singletonHook } from 'react-singleton-hook';
import {
	BN,
	BigNum,
	ZERO,
	getFuelOverflowAccountPublicKey,
} from '@drift-labs/sdk';
import { useEffect, useState } from 'react';
import useWalletIsConnected from './useWalletIsConnected';
import useLazySubAccounts from './useLazySubAccounts';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import { ZERO_BIGNUM } from 'src/constants/math';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDevSwitchIsOn from './useDevSwitchIsOn';

const FUEL_REWARDS_UPDATE_INTERVAL = 5000;

// Data for individual subaccounts, only used if devswitch is on (for now)
export type AccountDevData = {
	userId: number;
	accountPublicKey: string;
	takerFuel: number;
	makerFuel: number;
	positionFuel: number;
	depositFuel: number;
	borrowFuel: number;
	insuranceFuel: number;
	lastFuelBonusUpdateTs: number;
	includeSettled: string;
};

const DEFAULT_STATE = {
	takerVolumeFuel: ZERO_BIGNUM,
	makerVolumeFuel: ZERO_BIGNUM,
	openInterestFuel: ZERO_BIGNUM,
	depositsFuel: ZERO_BIGNUM,
	borrowsFuel: ZERO_BIGNUM,
	insuranceFuel: ZERO_BIGNUM,
	totalFuel: ZERO_BIGNUM,
	accountsDevData: [] as AccountDevData[],
	lastFuelIfBonusUpdateTs: 0,
	loaded: false,
	nowTs: 0,
};

// Replace this with actual data when SDK is ready
const useFuelEarnings = () => {
	const isDev = useDevSwitchIsOn();
	const connected = useWalletIsConnected();
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const accounts = useLazySubAccounts();
	const authority = useDriftStore((s) => s.wallet?.current?.adapter?.publicKey);

	const [accountsDevData, setAccountsDevData] = useState<AccountDevData[]>([]);

	const [loaded, setLoaded] = useState(false);
	const [takerVolumeFuel, setTakerVolumeFuel] = useState(ZERO_BIGNUM);
	const [makerVolumeFuel, setMakerVolumeFuel] = useState(ZERO_BIGNUM);
	const [openInterestFuel, setOpenInterestFuel] = useState(ZERO_BIGNUM);
	const [depositsFuel, setDepositsFuel] = useState(ZERO_BIGNUM);
	const [borrowsFuel, setBorrowsFuel] = useState(ZERO_BIGNUM);
	const [insuranceFuel, setInsuranceFuel] = useState(ZERO_BIGNUM);
	const [totalFuel, setTotalFuel] = useState(ZERO_BIGNUM);
	const [lastFuelIfBonusUpdateTs, setLastFuelIfBonusUpdateTs] = useState(0);
	const [nowTs, setNowTs] = useState(0);

	const getAndSetFuelEarnings = async () => {
		let _takerVolumeFuel = ZERO;
		let _makerVolumeFuel = ZERO;
		let _openInterestFuel = ZERO;
		let _depositsFuel = ZERO;
		let _borrowsFuel = ZERO;
		let _insuranceFuel = ZERO;
		const devData: AccountDevData[] = [];

		const _nowTs = new BN(Math.floor(Date.now() / 1000));
		setNowTs(_nowTs.toNumber());

		const userStats = driftClient.getUserStats();
		const userStatsAccount = userStats.getAccount();

		if (userStats && !accounts.length) {
			_insuranceFuel = userStats.getInsuranceFuelBonus(_nowTs, true, true);
			setTakerVolumeFuel(ZERO_BIGNUM);
			setMakerVolumeFuel(ZERO_BIGNUM);
			setOpenInterestFuel(ZERO_BIGNUM);
			setDepositsFuel(ZERO_BIGNUM);
			setBorrowsFuel(ZERO_BIGNUM);
			setInsuranceFuel(BigNum.from(_insuranceFuel));
			setTotalFuel(BigNum.from(_insuranceFuel));
		}

		// Add all subaccounts' fuel together, we may want to separate this out for each account later
		accounts
			.filter((account) => !account.isDelegatedTo)
			.forEach((account, i) => {
				const user = driftClient.getUser(account.userId);

				// Only include settled once because it's the same for all subaccounts
				const includeSettled = i === 0;
				const fuelBonus = user.getFuelBonus(_nowTs, includeSettled, true);

				_takerVolumeFuel = _takerVolumeFuel.add(fuelBonus.takerFuel);
				_makerVolumeFuel = _makerVolumeFuel.add(fuelBonus.makerFuel);
				_openInterestFuel = _openInterestFuel.add(fuelBonus.positionFuel);
				_depositsFuel = _depositsFuel.add(fuelBonus.depositFuel);
				_borrowsFuel = _borrowsFuel.add(fuelBonus.borrowFuel);
				_insuranceFuel = _insuranceFuel.add(
					includeSettled ? fuelBonus.insuranceFuel : ZERO
				);

				if (isDev) {
					devData.push({
						userId: account.userId,
						accountPublicKey: account.pubKey.toString(),
						includeSettled: `${includeSettled}`,
						takerFuel: fuelBonus.takerFuel.toNumber(),
						makerFuel: fuelBonus.makerFuel.toNumber(),
						positionFuel: fuelBonus.positionFuel.toNumber(),
						depositFuel: fuelBonus.depositFuel.toNumber(),
						borrowFuel: fuelBonus.borrowFuel.toNumber(),
						insuranceFuel: includeSettled
							? fuelBonus.insuranceFuel.toNumber()
							: 0,
						lastFuelBonusUpdateTs: user.getUserAccount().lastFuelBonusUpdateTs,
					});
				}
			});

		// add the overflow fuel if there is any
		if (userStatsAccount.fuelOverflowStatus === 1) {
			const fuelOverflowAccount =
				await driftClient.program.account.fuelOverflow.fetch(
					getFuelOverflowAccountPublicKey(
						driftClient.program.programId,
						userStatsAccount.authority
					)
				);

			_takerVolumeFuel = _takerVolumeFuel.add(
				fuelOverflowAccount.fuelTaker as BN
			);
			_makerVolumeFuel = _makerVolumeFuel.add(
				fuelOverflowAccount.fuelMaker as BN
			);
			_openInterestFuel = _openInterestFuel.add(
				fuelOverflowAccount.fuelPositions as BN
			);
			_depositsFuel = _depositsFuel.add(fuelOverflowAccount.fuelDeposits as BN);
			_borrowsFuel = _borrowsFuel.add(fuelOverflowAccount.fuelBorrows as BN);
			_insuranceFuel = _insuranceFuel.add(
				fuelOverflowAccount.fuelInsurance as BN
			);
		}

		if (isDev) {
			setLastFuelIfBonusUpdateTs(userStatsAccount.lastFuelIfBonusUpdateTs);
			setAccountsDevData(devData);
		}

		const _totalFuel = _takerVolumeFuel
			.add(_makerVolumeFuel)
			.add(_openInterestFuel)
			.add(_depositsFuel)
			.add(_borrowsFuel)
			.add(_insuranceFuel);

		setTakerVolumeFuel(BigNum.from(_takerVolumeFuel));
		setMakerVolumeFuel(BigNum.from(_makerVolumeFuel));
		setOpenInterestFuel(BigNum.from(_openInterestFuel));
		setDepositsFuel(BigNum.from(_depositsFuel));
		setBorrowsFuel(BigNum.from(_borrowsFuel));
		setInsuranceFuel(BigNum.from(_insuranceFuel));
		setTotalFuel(BigNum.from(_totalFuel));
	};

	useEffect(() => {
		if (!connected) {
			setLoaded(false);
		} else if (driftClient && driftClientIsReady) {
			getAndSetFuelEarnings();
			setLoaded(true);

			const timer = setInterval(() => {
				getAndSetFuelEarnings();
			}, FUEL_REWARDS_UPDATE_INTERVAL);

			return () => {
				clearInterval(timer);
			};
		}
	}, [connected, driftClient, driftClientIsReady, accounts, authority]);

	return {
		takerVolumeFuel,
		makerVolumeFuel,
		openInterestFuel,
		depositsFuel,
		borrowsFuel,
		insuranceFuel,
		totalFuel,
		accountsDevData,
		lastFuelIfBonusUpdateTs,
		loaded,
		nowTs,
	};
};

export default singletonHook(DEFAULT_STATE, useFuelEarnings);
