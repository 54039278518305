'use client';

import React from 'react';
import { Menu, MenuItem } from './DevDropdown';
import ExchangeHistoryClient from '../../utils/exchangeHistoryClient';
import { MarketType } from '@drift-labs/sdk';
import { ChevronDown } from '@drift-labs/icons';
import { notify } from 'src/utils/notifications';
import Env from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { DriftWindow } from 'src/window/driftWindow';
import useFeatureFlagStore from '../../stores/useFeatureFlagStore';
import useForceUpdate from '../../hooks/useForceUpdate';

const DevTopDropDown = () => {
	const actions = useDriftActions();
	const triggerRefresh = useForceUpdate();
	const showEmulationModal = () => {
		actions.showModal('showEmulationModal', true);
	};

	const getState = useDriftStore((s) => s.get);

	const [preflightEnabled, setPreflightEnabled] = React.useState(true);

	return (
		<Menu
			className="absolute top-[2px] left-0 h-[10px] w-[30px] bg-neutrals-50"
			// @ts-ignore
			label={<ChevronDown color="var(--text-secondary)" size={24} />}
		>
			<MenuItem
				label="Configure Feature Flags"
				onClick={() => {
					actions.showModal('showFeatureFlagModal', true);
				}}
			/>
			<Menu label="Misc. Actions">
				{/* Force liquidation alert */}
				<MenuItem
					label="Show liquidation alert for current account"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('force_liquidation_alert');
					}}
				/>
				{/* Drigger token account sync */}
				<MenuItem
					label="Trigger token account sync"
					onClick={() => {
						useDriftStore
							.getState()
							.appEventEmitter.emit('refreshTokenAccounts');
					}}
				/>
				{/* Useful to access onboarding steps */}
				<MenuItem
					label={`${
						preflightEnabled ? 'Disable' : 'Enable'
					} transaction preflight`}
					onClick={() => {
						// If preflight is currently enabled, then we are flipping it to disabled (skip preflight = true)
						const shouldSkipPreflight = preflightEnabled;

						getState().driftClient.client.opts.skipPreflight =
							shouldSkipPreflight;

						setPreflightEnabled(!preflightEnabled);

						triggerRefresh();
					}}
				/>
				<MenuItem
					label={`Show Prediction Mkts in perp trade page (current:${Env.showPredictionMarketsInPerpTradePage})`}
					onClick={() => {
						Env.showPredictionMarketsInPerpTradePage =
							!Env.showPredictionMarketsInPerpTradePage;
						triggerRefresh();
					}}
				/>
				<MenuItem
					label="Toggle Force Stale Data Warning"
					onClick={() => {
						DriftWindow.FORCE_DISPLAY_STALE_PAGE_WARNING =
							!DriftWindow.FORCE_DISPLAY_STALE_PAGE_WARNING;
					}}
				/>
				<MenuItem
					label="Clear Wallet Memory"
					onClick={() => {
						localStorage.removeItem('hasConnectedWalletBefore');
						localStorage.removeItem('hasCompletedOnboarding');
						localStorage.removeItem('lastAcknowledgedTerms');
					}}
				/>
				<MenuItem
					label="Withdraw all dust"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('withdraw_all_dust');
					}}
				/>
				<MenuItem
					label="Withdraw 5 USDC"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('withdraw_5_usdc');
					}}
				/>
				<MenuItem
					label="Reclaim Rent"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('reclaim_rent');
					}}
				/>
				<MenuItem
					label="Create anchor error tx"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('force_anchor_error_tx');
					}}
				/>
				<MenuItem
					label="Create drift error tx"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('force_drift_error_tx');
					}}
				/>
				<MenuItem
					label="Create insufficient solana wallet balance error"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'force_insufficient_wallet_balance_error'
						);
					}}
				/>
				<MenuItem
					label="Fetch top makers"
					onClick={async () => {
						const result =
							await ExchangeHistoryClient.getTopMakersKeysAndAccounts({
								marketIndex: 0,
								marketType: MarketType.PERP,
								side: 'bid',
								limit: 2,
							});

						console.log(result);
					}}
				/>
				<MenuItem
					label="Create random notification"
					onClick={() => {
						notify({
							// @ts-ignore
							type: ['success', 'error', 'warning', 'info'][
								Math.floor(Math.random() * 4)
							],
							message: `Random Notification`,
							description: `This is a description`,
							subDescription:
								Math.random() > 0.5 ? `This is a sub description` : undefined,
							id: Math.random().toString(),
							lengthMs: 1000_000 * Math.random(),
							action: {
								type: 'txnLink',
								txnSig: 'random_tx_sig',
							},
						});
					}}
				/>
				<MenuItem
					label="Emulate Account (with current signer)"
					onClick={showEmulationModal}
				/>
				<MenuItem
					label="Enable Logging Service"
					onClick={() => {
						DriftWindow.enableLoggingService();
					}}
				/>
				<MenuItem
					label="Log feature flag state"
					onClick={() => {
						console.log(useFeatureFlagStore.getState());
					}}
				/>
			</Menu>
			<Menu label="Market Order Toast Testing">
				<MenuItem
					label={`Toggle Context (current: ${DriftWindow.marketOrderToastTestingContext})`}
					onClick={() => {
						DriftWindow.marketOrderToastTestingContext =
							DriftWindow.marketOrderToastTestingContext === 'blockchain_event'
								? 'order_state'
								: 'blockchain_event';
						triggerRefresh();
					}}
				/>
				<MenuItem
					label="Trigger a new order"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'market_order_testing__manual_init'
						);
					}}
				/>
				<MenuItem
					label="Trigger previous order tx SIGNED"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'market_order_testing__manual_tx_signed'
						);
					}}
				/>
				<MenuItem
					label="Trigger previous order tx FAILED"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'market_order_testing__manual_tx_failed'
						);
					}}
				/>
				<MenuItem
					label="Trigger previous OrderRecord received"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'market_order_testing__manual_receive_order'
						);
					}}
				/>
				<MenuItem
					label="Trigger previous order INCREMENTAL FILL"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'market_order_testing__manual_incremental_fill'
						);
					}}
				/>
				<MenuItem
					label="Trigger previous order COMPLETE FILL"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'market_order_testing__manual_complete_fill'
						);
					}}
				/>
				<MenuItem
					label="Trigger previous order EXPIRED"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'market_order_testing__manual_order_expired'
						);
					}}
				/>
				<MenuItem
					label="Trigger previous order CANCELLED"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'market_order_testing__manual_order_cancelled'
						);
					}}
				/>
				<MenuItem
					label="[SIGNED MSG] Trigger a new order"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'signed_msg_order_market_order_testing__manual_init'
						);
					}}
				/>
				<MenuItem
					label="[SIGNED MSG] Trigger a new order message signed"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'signed_msg_order_market_order_testing__manual_sign_message'
						);
					}}
				/>
				<MenuItem
					label="[SIGNED MSG] Trigger receive confirmation"
					onClick={() => {
						DriftWindow.devEventEmitter.emit(
							'signed_msg_order_market_order_testing__manual_receive_confirmation'
						);
					}}
				/>
			</Menu>
			<Menu label="Posthog">
				<MenuItem
					label="Enable Session Recording"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('set_session_recording', true);
					}}
				/>
				<MenuItem
					label="Disable Session Recording"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('set_session_recording', false);
					}}
				/>
				<MenuItem
					label="Enable Event Capture"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('set_event_capture', true);
					}}
				/>
				<MenuItem
					label="Disable Event Capture"
					onClick={() => {
						DriftWindow.devEventEmitter.emit('set_event_capture', false);
					}}
				/>
				<MenuItem
					label={`${
						DriftWindow.forceInvariantCheckFailure ? 'Disable' : 'Enable'
					} FORCE invariant check failure`}
					onClick={() => {
						DriftWindow.forceInvariantCheckFailure =
							!DriftWindow.forceInvariantCheckFailure;
						triggerRefresh();
					}}
				/>
			</Menu>
		</Menu>
	);
};

export default DevTopDropDown;
