import {
	Icons,
	NotificationsButton,
	NotificationStyle,
	NotificationTypeStyles,
} from '@dialectlabs/react-ui';
import React from 'react';
import Env, {
	DriftTheme,
} from '../../environmentVariables/EnvironmentVariables';
import { DialectSolanaSdk } from '@dialectlabs/react-sdk-blockchain-solana';
import useDriftTheme from 'src/hooks/useDriftTheme';
import Button from 'src/components/Button';
import { ZINDEX } from 'src/utils/uiUtils';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { Bankrupt, Notification, WarningFilled } from '@drift-labs/icons';

// # dapp address here
const DAPP_ADDRESS = Env.driftDialectDappAddress;

// # Configure Notification Styles (Note: This is only for icons and link colours, other styles for dialect are configured in styles/index.css under `.dialect`)
const ICON_SIZE_STYLE = { height: '20px', width: '20px' };

const DEFAULT_ALERT_STYLE: NotificationStyle = {
	Icon: <Notification style={ICON_SIZE_STYLE} />,
	iconColor: 'var(--text-default)',
	iconBackgroundBackdropColor: 'var(--container-bg-hover)',
	// linkColor: 'var(--warn-yellow)',
};

NotificationTypeStyles['announcements'] = DEFAULT_ALERT_STYLE;
NotificationTypeStyles['filled-order'] = DEFAULT_ALERT_STYLE;
NotificationTypeStyles['liquidation-warning'] = {
	Icon: <WarningFilled style={ICON_SIZE_STYLE} />,
	iconColor: 'var(--warn-yellow)',
	iconBackgroundBackdropColor: 'var(--warn-yellow-secondary-bg)',
	linkColor: 'var(--warn-yellow)',
};
NotificationTypeStyles['liquidation-alert'] = {
	Icon: <Bankrupt style={ICON_SIZE_STYLE} />,
	iconColor: 'var(--negative-red)',
	iconBackgroundBackdropColor: 'var(--negative-red-secondary-bg)',
	linkColor: 'var(--negative-red)',
};

// # Dialect Notifications Initialization
export const DialectNotificationsButton = () => {
	const theme = useDriftTheme();

	const isMobile = useIsMobileScreenSize();

	return (
		<DialectSolanaSdk
			dappAddress={DAPP_ADDRESS}
			config={{
				environment: Env.sdkEnv === 'devnet' ? 'development' : 'production',
			}}
		>
			<NotificationsButton
				theme={theme === DriftTheme.dark ? 'dark' : 'light'}
				channels={['wallet', 'telegram']}
				renderModalComponent={({ open, setOpen: _setOpen, ref, children }) => {
					if (!open) {
						return null;
					}

					return (
						<div
							ref={ref}
							className={`fixed bg-container-bg ${
								isMobile ? 'inset-0' : 'w-[420px] inset-y-0 right-0'
							} ${ZINDEX.xTop}`}
						>
							{children}
						</div>
					);
				}}
			>
				{({ setOpen, unreadCount, ref }) => {
					return (
						<div className="relative">
							<Button.Secondary
								ref={ref}
								size="MEDIUM"
								iconOnly
								onClick={() => setOpen((prev) => !prev)}
								className="w-8 h-8"
								id="dialect-notifications-button"
							>
								{Icons.Bell({
									height: 16,
									width: 16,
								})}
							</Button.Secondary>
							{unreadCount && unreadCount > 0 ? (
								<div className="absolute inline-flex items-center justify-center w-[18px] h-[18px] text-[10px] text-white 	bg-negative-red rounded-full -bottom-[6px] -right-[6px]">
									<span className="pt-[1px]">
										{unreadCount > 9 ? '9+' : unreadCount}
									</span>
								</div>
							) : null}
						</div>
					);
				}}
			</NotificationsButton>
		</DialectSolanaSdk>
	);
};
