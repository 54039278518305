// import useSWR from 'swr';
import StatusBar from './StatusBar';
import Link from '../Utils/Link';

const SolanaDownStatus = () => {
	const data: any[] = [];
	const error: null | any = null;

	// TODO: temporarily disabled since API fails majority of the time. Need to find a better source for this data.
	// TODO: Add manual override for this status bar using params.txt

	// const { data, error } = useSWR<
	// 	{
	// 		submitted: number;
	// 		confirmed: number;
	// 		loss: string;
	// 		mean_ms: number;
	// 		ts: string;
	// 		error_count: number;
	// 		error: string;
	// 	}[]
	// >('https://ping.solana.com/mainnet-beta/last6hours', null, {
	// 	fallbackData: [],
	// });

	let isDegradedPerformanceInPast5Mins = false;
	try {
		isDegradedPerformanceInPast5Mins =
			data.length > 0 &&
			data
				.slice(0, 5)
				.every(
					(d) => d?.confirmed === 0 || d?.mean_ms > 20_000 || d?.mean_ms === 0
				);
	} catch (err) {
		return null;
	}

	if (!isDegradedPerformanceInPast5Mins || error) {
		return null;
	}

	return (
		<StatusBar
			message={
				<div className="flex justify-center w-full text-center">
					Solana network is currently suffering from degraded performance.{' '}
					<Link
						href="https://explorer.solana.com/"
						className="ml-1 text-black underline"
					>
						{` Visit the explorer to view the current status on Solana.`}
					</Link>
				</div>
			}
		/>
	);
};

export default SolanaDownStatus;
