'use client';

import React, { useEffect, useRef, useState } from 'react';
import useNavigationState, { NavigationState } from './useNavigationState';
import {
	BASE_NAVIGATION_OPTIONS,
	DesktopCategoryOption,
	DesktopNavigationOption,
	DesktopStaticOption,
	MOBILE_NAVIGATION_OPTIONS,
	MOBILE_OPTIONS_ICONS,
	MobileNavigationOptions,
} from '.';
import Text from '../Text/Text';
import { twMerge } from 'tailwind-merge';
import {
	ChevronLeft,
	ChevronRight,
	Layout,
	Settings,
	Menu,
	Hamburger,
} from '@drift-labs/icons';
import Button from '../Button';
import MobileHelpButton from '../Buttons/MobileHelpButton';
import CurrentRpcHealth from '../CurrentRpcHealth';
import PriorityFeeDisplay from '../PriorityFeeDisplay';
import Drawer from '../Drawer';
import Accordion from '../Accordion';
import useDriftActions from 'src/hooks/useDriftActions';
import { useTheme } from 'next-themes';
import XButton from '../Buttons/XButton';
import useTradeModeSetting, { TradeMode } from 'src/hooks/useTradeModeSetting';
import Env from 'src/environmentVariables/EnvironmentVariables';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import MarketIcon from '../Utils/MarketIcon';
import useNavigationStore, {
	NavigationStore,
} from 'src/stores/useNavigationStore';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import { LabelChip } from '../Chips/LabelChip';
import { PosthogChangeDisplaySettings } from 'src/@types/posthog';

// Should find a more modular way to do sub-panels of this overall component
// But for now for MVP, this will just be controlled by a useState in the parent component
const MobileDisplaySettings = ({ onBack }: { onBack: () => void }) => {
	const [savedSettings, updateSettings] = useCurrentSettings();
	const currentThemeSetting = savedSettings.theme;
	const { theme: currentTheme } = useTheme();
	const [tradeMode, setTradeMode] = useTradeModeSetting();

	const initialTradeMode = useRef('');
	const initialTheme = useRef('');

	const { captureEvent } = usePostHogCapture();

	const handleSetLightTheme = () => {
		updateSettings({ ...savedSettings, theme: 'light' });
	};

	const handleSetDarkTheme = () => {
		updateSettings({ ...savedSettings, theme: 'dark' });
	};

	const handleSetAutoTheme = () => {
		updateSettings({ ...savedSettings, theme: 'device' });
	};

	const handleSetLiteTradeMode = () => {
		setTradeMode(TradeMode.LITE);
	};

	const handleSetProTradeMode = () => {
		setTradeMode(TradeMode.PRO);
	};

	// Set refs to the initial selection of each setting type
	const resetInitialChoiceRefs = () => {
		initialTradeMode.current = tradeMode;
		initialTheme.current = currentTheme;
	};

	const handleBack = () => {
		const captureObject: PosthogChangeDisplaySettings = {
			trade_mode: tradeMode !== initialTradeMode.current ? tradeMode : null,
			layout: null,
			theme: currentTheme !== initialTheme.current ? currentTheme : null,
			notification: null,
		};

		const anyChanges = Object.values(captureObject).some(
			(value) => value !== null
		);

		if (anyChanges) {
			captureEvent('changed_display_settings', captureObject);
		}

		resetInitialChoiceRefs();

		onBack();
	};

	// Set initial choice refs on first render
	useEffect(() => {
		resetInitialChoiceRefs();
	}, []);

	return (
		<div className="w-full h-full bg-container-bg-hover text-text-default">
			<div className="flex flex-row items-center justify-between w-full p-3 pt-4">
				<div onTouchStart={handleBack} className="flex flex-row items-center">
					<ChevronLeft size={24} color="var(--text-label)" className="mr-4" />
					<Text.H3>Display</Text.H3>
				</div>
				<XButton
					onClick={handleBack}
					stroke="var(--text-label)"
					className="relative top-0.5"
				/>
			</div>
			<div className="px-4 pt-4 mb-2 border-t border-container-border">
				<Text.H4>Theme</Text.H4>
			</div>
			<div className="flex flex-col items-center justify-between w-full p-4 pt-0">
				<button
					className={twMerge(
						currentThemeSetting === 'dark'
							? 'rainbow-gradient-border bg-container-bg-hover'
							: '',
						'rounded my-2 p-4 w-full flex flex-row items-center justify-between'
					)}
					onTouchStart={handleSetDarkTheme}
				>
					<Text.H4>Dark Theme</Text.H4>
					<img src="/assets/icons/buttonIcons/themes/dark-theme-mobile.svg" />
				</button>
				<button
					className={twMerge(
						currentThemeSetting === 'light'
							? 'rainbow-gradient-border bg-container-bg-hover'
							: '',
						'rounded my-2 p-4 w-full flex flex-row items-center justify-between'
					)}
					onTouchStart={handleSetLightTheme}
				>
					<Text.H4>Light Theme</Text.H4>
					<img src="/assets/icons/buttonIcons/themes/light-theme-mobile.svg" />
				</button>
				<button
					className={twMerge(
						currentThemeSetting === 'device'
							? 'rainbow-gradient-border bg-container-bg-hover'
							: '',
						'rounded my-2 p-4 w-full flex flex-row items-center justify-between'
					)}
					onTouchStart={handleSetAutoTheme}
				>
					<Text.H4>Auto</Text.H4>
					<img src="/assets/icons/buttonIcons/themes/device-theme-mobile.svg" />
				</button>
			</div>

			{Env.enableLiteMode && (
				<>
					<div className="px-4 pt-4 mb-2 border-t border-container-border">
						<Text.H4>Trade Mode</Text.H4>
					</div>
					<div className="flex flex-col items-center justify-between w-full p-4 pt-0">
						<button
							className={twMerge(
								tradeMode === TradeMode.LITE
									? 'rainbow-gradient-border bg-container-bg-hover'
									: '',
								'rounded my-2 p-4 w-full flex flex-row items-center justify-between'
							)}
							onTouchStart={handleSetLiteTradeMode}
						>
							<div className="flex flex-col items-start pr-8 text-left">
								<Text.H4>Lite</Text.H4>
								<Text.BODY2>
									Simplifies trading by prioritizing essential options
								</Text.BODY2>
							</div>
							<img
								src={`/assets/icons/buttonIcons/tradeMode/lite-${currentTheme}-mobile.svg`}
							/>
						</button>
						<button
							className={twMerge(
								tradeMode === TradeMode.PRO
									? 'rainbow-gradient-border bg-container-bg-hover'
									: '',
								'rounded my-2 p-4 w-full flex flex-row items-center justify-between'
							)}
							onTouchStart={handleSetProTradeMode}
						>
							<div className="flex flex-col items-start pr-8 text-left">
								<Text.H4>Pro</Text.H4>
								<Text.BODY2>
									A comprehensive set of trading features, order types, and
									information
								</Text.BODY2>
							</div>
							<img
								src={`/assets/icons/buttonIcons/tradeMode/pro-${currentTheme}-mobile.svg`}
							/>
						</button>
					</div>
				</>
			)}
		</div>
	);
};

export const MobileNavigationMenuToggle = () => {
	const setNavigationStore = useNavigationStore((s) => s.set);

	const handleClick = () => {
		setNavigationStore((s) => {
			s.showMobileNavMenu = true;
		});
	};

	return (
		<Button.Secondary
			size="MEDIUM"
			onClick={handleClick}
			iconOnly
			className="px-[4px] py-[4px] w-8"
		>
			<Hamburger color="var(--text-emphasis)" size={24} />
		</Button.Secondary>
	);
};

export const MobileNavigationMenuButton = ({
	handleNavigation,
	option,
	currentNavigationSelection,
}: {
	option: MobileNavigationOptions[0];
	handleNavigation: NavigationState['handleNavigation'];
	currentNavigationSelection: NavigationStore['currentNavigationSelection'];
}) => {
	const { connected } = useCurrentWalletAdapter();

	const handleClick = () => {
		if (option.key === 'overview') {
			if (connected) {
				handleNavigation(BASE_NAVIGATION_OPTIONS.overviewBalances.handler());
			} else {
				handleNavigation(BASE_NAVIGATION_OPTIONS.overview.handler());
			}
		}

		handleNavigation(option.handler());
	};

	const isHighlighted = currentNavigationSelection === option.key;

	return (
		<div
			onTouchStart={handleClick}
			className={twMerge(
				`flex flex-row items-center justify-between w-full pl-2 py-3 pr-2`,
				isHighlighted ? 'text-gradient-1' : 'text-text-default'
			)}
		>
			<Text.H3>{option.name}</Text.H3>
			{option.chipText && <LabelChip type="new" label={option.chipText} />}
		</div>
	);
};

export const MobileNavigationMenu = () => {
	const actions = useDriftActions();
	const navigationState = useNavigationState();
	const [showDisplaSettings, setShowDisplaySettings] = useState(false);
	const setNavigationStore = useNavigationStore((s) => s.set);
	const currentNavigationSelection = useNavigationStore(
		(s) => s.currentNavigationSelection
	);

	const handleClose = () => {
		setNavigationStore((s) => {
			s.showMobileNavMenu = false;
		});
	};

	const handleShowAllSettings = () => {
		actions.showModal('showSettingsModal');
	};

	const handleShowDisplaySettings = () => {
		setShowDisplaySettings(true);
	};

	const handleHideDisplaySettings = () => {
		setShowDisplaySettings(false);
	};

	let _defaultExpandedIndex: number | undefined = undefined;

	const accordionOptions = Object.keys(MOBILE_NAVIGATION_OPTIONS)
		.filter(
			(key) =>
				!(
					MOBILE_NAVIGATION_OPTIONS[
						key as keyof typeof MOBILE_NAVIGATION_OPTIONS
					] as DesktopNavigationOption
				).hideOnMobileNav
		)
		.map((key, categoryIndex) => {
			const optionCategory = MOBILE_NAVIGATION_OPTIONS[
				key as keyof typeof MOBILE_NAVIGATION_OPTIONS
			] as DesktopCategoryOption;
			const Icon =
				MOBILE_OPTIONS_ICONS[key as keyof typeof MOBILE_OPTIONS_ICONS];
			const isStaticNavigation =
				MOBILE_NAVIGATION_OPTIONS[key as keyof typeof MOBILE_NAVIGATION_OPTIONS]
					.type === 'staticOption';
			const isStaticNavigationSelected =
				isStaticNavigation && currentNavigationSelection === key;

			const content = isStaticNavigation ? null : (
				<>
					{optionCategory.options.map((option) => {
						if (currentNavigationSelection === option.key) {
							_defaultExpandedIndex = categoryIndex;
						}

						return (
							<MobileNavigationMenuButton
								handleNavigation={navigationState.handleNavigation}
								key={option.name}
								option={option}
								currentNavigationSelection={currentNavigationSelection}
							/>
						);
					})}
				</>
			);

			return {
				header: (
					<div
						className={twMerge(
							'flex flex-row items-center',
							isStaticNavigationSelected && 'text-gradient-1'
						)}
						onClick={
							isStaticNavigation
								? () =>
										navigationState.handleNavigation(
											(
												MOBILE_NAVIGATION_OPTIONS[
													key as keyof typeof MOBILE_NAVIGATION_OPTIONS
												] as DesktopStaticOption
											).handler()
										)
								: undefined
						}
					>
						{key === 'more' ? (
							<Menu size={24} className="mr-[15px]" color="var(--text-label)" />
						) : Icon ? (
							<Icon
								size={24}
								className="mr-[15px]"
								color={
									isStaticNavigationSelected
										? 'url(#icon-gradient)'
										: 'var(--text-label)'
								}
							/>
						) : null}
						<span className="mr-2">{optionCategory.categoryName}</span>
						{optionCategory.chipText && (
							<LabelChip type="new" label={optionCategory.chipText} />
						)}
					</div>
				),
				content: content,
				disabled: isStaticNavigation,
				showIcon: !isStaticNavigation,
			};
		});

	const navigationContent = (
		<div className="flex flex-col justify-between h-full">
			<div className="flex flex-col justify-between flex-shrink h-full">
				<div className="">
					<div className="flex flex-col">
						<div className="pl-2 pr-3">
							<Accordion memoedOptions={accordionOptions} navigationStyle />
						</div>
						<div className="flex flex-row items-center w-full pb-1 pl-4 pr-3">
							<MarketIcon
								marketSymbol="DRIFT"
								sizeClass="w-[22px] h-[22px]"
								className="mr-2"
								grey={
									currentNavigationSelection !==
									BASE_NAVIGATION_OPTIONS.driftStaking.key
								}
							/>
							<MobileNavigationMenuButton
								handleNavigation={navigationState.handleNavigation}
								key={BASE_NAVIGATION_OPTIONS.driftStaking.key}
								option={{
									...BASE_NAVIGATION_OPTIONS.driftStaking,
									name: 'Stake DRIFT',
								}}
								currentNavigationSelection={currentNavigationSelection}
							/>
						</div>
						<div
							className="flex flex-row items-center justify-between w-full py-4 pl-4 pr-5 mt-2 border-t border-container-border"
							onClick={handleShowAllSettings}
						>
							<div className="flex flex-row items-center">
								<Settings
									size={24}
									color="var(--text-label)"
									className="mr-[15px]"
								/>
								<Text.H3 className="text-text-default">Settings</Text.H3>
							</div>

							<ChevronRight color="var(--text-label)" size={16} />
						</div>
						<div
							className="flex flex-row items-center justify-between w-full py-4 pl-4 pr-5 border-b border-container-border"
							onClick={handleShowDisplaySettings}
						>
							<div className="flex flex-row items-center">
								<Layout
									size={24}
									color="var(--text-label)"
									className="mr-[15px]"
								/>

								<Text.H3 className="text-text-default">Display</Text.H3>
							</div>
							<ChevronRight color="var(--text-label)" size={16} />
						</div>
					</div>
				</div>
				<div className="flex flex-row items-stretch justify-around mt-5 overflow-y-auto border-t border-container-border hidden-scroll">
					<div className="m-3">
						<CurrentRpcHealth mobile />
					</div>

					<div className="m-3">
						<PriorityFeeDisplay mobile showIcon />
					</div>

					<div className="m-3">
						<MobileHelpButton />
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<Drawer className="sm:w-[420px] sm:max-w-[420px] bg-container-bg-hover max-h-[100vh] relative hidden-scroll overflow-x-hidden">
			<Drawer.Header
				className="bg-container-bg-hover"
				titleContent={null}
				onClose={handleClose}
				closeButtonSize={24}
			/>
			{navigationContent}
			<div
				className={twMerge(
					'absolute top-0 h-full w-full transition-all left-[100%]',
					showDisplaSettings && 'left-0'
				)}
			>
				<MobileDisplaySettings onBack={handleHideDisplaySettings} />
			</div>
		</Drawer>
	);
};
