'use client';

import Button from '../Button';
import Text from '../Text/Text';
import { BASE_NAVIGATION_OPTIONS, RouteNavigationResult } from '../Navigation';
import Link from 'next/link';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useFuelEarnings from 'src/hooks/useFuelEarnings';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import useAccountExists from 'src/hooks/useAccountExists';
import { BN } from '@drift-labs/sdk';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Tooltip from '../Tooltip/Tooltip';
import { Calendar, Diamond, Trophy } from '@drift-labs/icons';
import Env from 'src/environmentVariables/EnvironmentVariables';
import Utility from '../Inputs/Utility';
import useGoToRoute from 'src/hooks/useGoToRoute';

const ONE_THOUSAND = new BN(1000);

const FuelTooltipContent = () => {
	const fuelEarnings = useFuelEarnings();
	const goToRoute = useGoToRoute();
	const { userRank, milestonesReached } = useDriftStore((s) => s.fuelStats);

	const campaignEnd = 'June 2025';

	return (
		<div className="flex flex-col h-full bg-container-bg w-full p-2 pb-0">
			<div className="justify-center items-center w-full text-center">
				<div className="text-text-label">Your Total Fuel Points</div>
				<img
					className="justify-self-center h-8 w-8 my-1"
					src="/assets/images/fuel/fuel-droplet.svg"
					alt="Fuel droplet"
				/>
				<Text.H1 className="fuel-gradient-text font-bold">
					{fuelEarnings?.totalFuel?.prettyPrint() || '0'}
				</Text.H1>
			</div>
			<div className="flex flex-col gap-2 mt-4">
				{Env.enableFuelLeaderboard && (
					<div className="flex w-full justify-between">
						<div className="flex items-center gap-2 text-text-label">
							<Trophy />
							<span className="text-text-label">Global Rank</span>
						</div>
						<span className="text-text-default">
							{userRank?.toLocaleString()}
						</span>
					</div>
				)}

				<div className="flex w-full justify-between">
					<div className="flex items-center gap-2 text-text-label">
						<Calendar />
						<span className="text-text-label">Campaign End</span>
					</div>
					<span className="text-text-default">{campaignEnd}</span>
				</div>

				<div className="flex w-full justify-between">
					<div className="flex items-center gap-2 text-text-label">
						<Diamond />
						<span className="text-text-label">Milestone</span>
					</div>
					<span
						className={
							milestonesReached > 0 ? 'text-emerald-400' : 'text-text-default'
						}
					>{`Unlocked ${
						milestonesReached > 0 ? `No.${milestonesReached}` : 'None'
					}`}</span>
				</div>
			</div>
			<Utility.VERTSPACERM />
			<Utility.VERTDIVIDER />
			<div className="w-full h-full flex items-center justify-center">
				<Text.BODY1
					className="text-text-interactive hover:cursor-pointer flex items-center justify-self-center text-center text-purple-40"
					onClick={() => goToRoute(`/fuel`)}
				>{`View More  >`}</Text.BODY1>
			</div>
		</div>
	);
};

export const FuelChip = () => {
	const isMobile = useIsMobileScreenSize();
	const connected = useWalletIsConnected();
	const accountExists = useAccountExists();
	const fuelEarnings = useFuelEarnings();
	const loaded = fuelEarnings?.loaded;
	const totalFuel = fuelEarnings?.totalFuel;

	const emulationMode = useDriftStore((s) => s.isEmulatingAccount);
	const displayFuelNumber = (emulationMode || connected) && accountExists;

	return (
		<Tooltip
			allowHover
			slim
			disabled={!connected}
			content={<FuelTooltipContent />}
			className="rounded-lg"
			tooltipClassName="rounded-lg p-0 w-[240px] h-[257px]"
		>
			<Link
				href={
					(BASE_NAVIGATION_OPTIONS.fuel.handler() as RouteNavigationResult)
						.route
				}
				className={'hover:opacity-100'}
			>
				<Button.Ghost size="SMALL" className="flex items-center h-full gap-2">
					<img src="/assets/images/fuel/fuel-droplet.svg" alt="Fuel droplet" />
					{!isMobile && displayFuelNumber && totalFuel ? (
						loaded ? (
							<Text.H3 className="hidden font-bold sm:block fuel-gradient-text">
								{totalFuel.gte(ONE_THOUSAND)
									? totalFuel.toMillified()
									: totalFuel.toNum()}
							</Text.H3>
						) : (
							<SkeletonValuePlaceholder className="w-8 h-5" loading />
						)
					) : (
						<Text.H3 className="hidden font-bold sm:block">Fuel</Text.H3>
					)}
				</Button.Ghost>
			</Link>
		</Tooltip>
	);
};
